import {gql} from '@apollo/client';

// FYI, acquisition
export const AUTHENTICATE_BY_COBORROWER_TOKEN = gql`
  mutation authenticateByCoborrowerToken($token: String!) {
    authenticateByCoborrowerToken(token: $token) {
      jwt
    }
  }
`;
