import React, {useEffect} from 'react';

import {Box} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {useLocalStorage, useScreenSize} from '@renofi/utils';
import {gray} from '@renofi/utils/src/colors';

import {coborrowerVar} from '../api/cache';
import {Heading, Small} from '../components';

import {DesktopIcon, MobileIcon} from './img';

const Finish = () => {
  const {isDesktop} = useScreenSize();
  const coborrower = useReactiveVar(coborrowerVar);
  const borrowerName = [coborrower?.firstName, coborrower?.lastName].join(' ');
  const [, setCompleted] = useLocalStorage('renofi:coborrower-completedAt');

  useEffect(() => {
    setCompleted(new Date());
  }, []);

  return (
    <Box pt={[0, 50]} pb={50} color={gray}>
      {isDesktop ? <DesktopIcon /> : <MobileIcon />}
      <Heading mt={[28, 35]} mb={[25, 30]}>
        Thanks for your authorization
      </Heading>
      <Box width={['100%', '100%']} mx="auto">
        <Small css={{lineHeight: '33px'}}>
          If we have trouble pulling credit someone will reach out to{' '}
          {borrowerName} before we begin our renovation underwriting.
        </Small>
      </Box>
    </Box>
  );
};

export default Finish;
