import {isEmpty, isObject} from 'lodash';

export const getCoborrowerAddress = (coborrower) => {
  const address = [
    coborrower?.coborrowerResidentialAddressStreet,
    coborrower?.coborrowerResidentialAddressCity,
    coborrower?.coborrowerResidentialAddressState,
    coborrower?.coborrowerResidentialAddressZip,
  ].filter(Boolean);

  return address?.join(', ');
};

export const isVerificationDone = (identity) =>
  identity?.identityVerifications?.some(
    (identity) =>
      identity.borrowerRole === 'coborrower' && identity.status !== 'pending',
  );

export const isVerificationNeeded = (identity) =>
  isObject(identity) && !isEmpty(identity) && !isVerificationDone(identity);

export const isSoftCreditCheckNeeded = (softCreditCheckTask) =>
  softCreditCheckTask?.status !== 'completed';
