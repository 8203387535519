import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import {START_VERIFICATION} from '../mutations/startVerification';

export default function useSubmitContact({onCompleted = noop} = {}) {
  const [startVerification, {data, loading, error}] = useMutation(
    START_VERIFICATION,
    {onCompleted},
  );
  const response = data?.submitContact;
  return {startVerification, response, loading, error};
}
