import React, {useEffect} from 'react';

import {isUndefined} from 'lodash';
import PropTypes from 'prop-types';
import {useHistory, useParams} from 'react-router-dom';
import {Flex} from 'rebass';
import * as Sentry from '@sentry/react';

import {logger, setItem, useLocalStorage} from '@renofi/utils';
import Loader from '@renofi/components/src/Loader';
import {lightGray} from '@renofi/utils/src/colors';

import {useAuthenticateByCoborrowerToken} from '../api';

const Auth = ({failedPath, onSuccess}) => {
  const {token, path} = useParams();
  const history = useHistory();
  const [, setToken] = useLocalStorage('renofi:token');
  const {authenticateByCoborrowerToken, response} =
    useAuthenticateByCoborrowerToken();

  useEffect(() => {
    (async () => {
      if (!token) {
        logger.error(
          'Missing token, use the token at /coborrower/token/:token',
        );
        history.push(failedPath);
        return;
      }

      setToken(token);
      try {
        await authenticateByCoborrowerToken({variables: {token}});
      } catch {
        onError();
      }
    })();
  }, []);

  useEffect(() => {
    if (isUndefined(response)) return;

    if (response.jwt) {
      setItem({key: 'coborrower-jwt', value: response.jwt});
      onSuccess(token, path);
    }
  }, [response]);

  function onError() {
    if (failedPath) return history.push(failedPath);

    Sentry.captureException(
      new Error(
        "Can't redirect from /auth page because no redirect path is specified.",
      ),
      {
        extra: {path},
      },
    );
  }

  return (
    <Flex height="100%" justifyContent="center" alignItems="center">
      <Loader color={lightGray} size={1.5} />
    </Flex>
  );
};

Auth.propTypes = {
  failedPath: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
};

export default Auth;
