import React from 'react';

import {Text} from 'rebass';

import {useLocalStorage} from '@renofi/utils';

const InvalidToken = () => {
  const [completed] = useLocalStorage('renofi:coborrower-completedAt');

  return (
    <>
      {completed ? (
        <>
          <Text mb={[0, 40]} mt={[0, 40]} mx={[0, 40]}>
            This task has been completed. Thank you.
          </Text>
        </>
      ) : (
        <>
          <Text mt={[0, 40]} mx={[0, 40]}>
            This link has expired. <br />
            Please ask primary borrower to resend a new link or contact us at:{' '}
            <br />
          </Text>

          <Text mt={[32, 40]} mb={[0, 40]} mx={[0, 40]}>
            phone: 855-736-6341
            <br /> email: sayhi@renofi.com
          </Text>
        </>
      )}
    </>
  );
};

export default InvalidToken;
