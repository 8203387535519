import React, {useEffect, useState} from 'react';

import {Switch, Route, Redirect, useHistory} from 'react-router-dom';
import Details from 'Details';
import {useReactiveVar} from '@apollo/client';

import {useScrollToTopBetweenRoutes} from '@renofi/utils';
import {Panel, Loader} from '@renofi/components';
import logrocket from '@renofi/analytics/src/logrocket2';

import {Wrapper} from '../components';
import Intro from '../Intro';
import InvalidToken from '../InvalidToken';
import NotFound from '../NotFound';
import DateOfBirth from '../DateOfBirth';
import Authorization from '../Authorization';
import NewAddress from '../NewAddress';
import Finish from '../Finish';
import {BASE_PATH} from '../const';
import {useCoborrowerTasks} from '../api';
import {coborrowerVar, identityVar} from '../api/cache';
import {isSoftCreditCheckNeeded, isVerificationNeeded} from '../utils';

const getFullName = (arr = []) => arr.filter(Boolean).join(' ').trim();

function App() {
  const [softCreditCheckTask, setSoftCreditCheckTask] = useState({});
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const fetchTasks = useCoborrowerTasks();
  const identity = useReactiveVar(identityVar);
  const coborrower = useReactiveVar(coborrowerVar);

  useScrollToTopBetweenRoutes();

  useEffect(() => {
    (async () => {
      const {softCreditCheckTask, identityVerificationTask} =
        (await fetchTasks()) || {};

      if (
        !isSoftCreditCheckNeeded(softCreditCheckTask) &&
        !isVerificationNeeded(identityVerificationTask)
      ) {
        history.push(`${BASE_PATH}/finish`);
      }

      const {
        coborrowerFirstName,
        coborrowerLastName,
        coborrowerEmail,
        projectId,
      } = softCreditCheckTask?.softCreditCheck || {};
      const name = getFullName([coborrowerFirstName, coborrowerLastName]);

      if (Boolean(coborrowerEmail && name)) {
        logrocket.identify(projectId, {
          email: coborrowerEmail,
          name,
        });
      }

      setSoftCreditCheckTask(softCreditCheckTask);
      setLoading(false);
    })();
  }, []);

  return (
    <Switch>
      <Wrapper>
        <Panel
          background="white"
          css={{
            padding: ['0 15px', '0 80px'],
            marginTop: [0, 40],
            paddingBottom: [0, 0],
          }}>
          {loading ? (
            <Loader color="#FF5253" size={2.5} />
          ) : (
            <Switch>
              <Route path={`${BASE_PATH}/intro`}>
                <Intro />
              </Route>

              <Route path={`${BASE_PATH}/date-of-birth`}>
                <DateOfBirth
                  softCreditCheckTask={softCreditCheckTask}
                  identity={identity}
                  email={coborrower?.coborrowerEmail}
                  projectId={coborrower?.projectId}
                />
              </Route>

              <Route path={`${BASE_PATH}/details`}>
                <Details />
              </Route>

              <Route path={`${BASE_PATH}/authorization`}>
                <Authorization />
              </Route>

              <Route path={`${BASE_PATH}/new-address`}>
                <NewAddress />
              </Route>

              <Route path={`${BASE_PATH}/finish`}>
                <Finish />
              </Route>

              <Route path={`${BASE_PATH}/invalid-token`}>
                <InvalidToken />
              </Route>

              <Route path={`${BASE_PATH}/not-found`}>
                <NotFound />
              </Route>

              <Redirect to={`${BASE_PATH}/intro`} />
            </Switch>
          )}
        </Panel>
      </Wrapper>
    </Switch>
  );
}

export default App;
