import React, {useRef, useState} from 'react';

import {noop, mapValues} from 'lodash';
import PropTypes from 'prop-types';
import {PatternFormat} from 'react-number-format';

import {useAutofocus} from '@renofi/utils';
import {red} from '@renofi/utils/src/colors';
import {splitDateString} from '@renofi/utils/src/date';

import {Input, InputWrapper} from '../TextField/styled';
import {Message} from '../TextField';

const DateField = ({
  onChange = noop,
  onBlur = noop,
  value,
  autofocus,
  required,
  isDirty = false,
  labelCss,
  ...props
}) => {
  const [dirty, setDirty] = useState(isDirty || Boolean(value));
  const [error, setError] = useState(validate(value));
  const ref = useRef();

  useAutofocus(autofocus, ref, value);

  function onChangeValue({value}) {
    const numbersOnly = value.replace(/\D/g, '');
    const errorMsg = validate(numbersOnly);
    setError(errorMsg);
    onChange(numbersOnly.slice(0, 10), errorMsg || null);
  }

  function toNumbers(object) {
    return mapValues(object, (prop) => Number(prop));
  }

  function validate(value) {
    const {day, month, year} = toNumbers(splitDateString(value));
    const today = new Date().getFullYear();
    const isCompleted = value?.length === 8;

    if (isCompleted && (month === 0 || month > 12)) {
      return 'Invalid date. Month must be between 1 and 12';
    }
    if (isCompleted && (day === 0 || day > 31)) {
      return 'Invalid date. Day must be between 1 and 31';
    }
    if (isCompleted && (year < 1900 || year > today)) {
      return `Invalid date. Year must be between 1900 and ${today}`;
    }
    if (dirty && required && (!value || value.length < 8)) {
      return 'This is required';
    }
    return '';
  }

  return (
    <InputWrapper xLarge={props.xLarge} icon="date">
      <PatternFormat
        getInputRef={ref}
        data-testid="date-field"
        value={value}
        onBlur={() => {
          setDirty(true);
          onBlur();
        }}
        onValueChange={onChangeValue}
        icon
        format="##/##/####"
        placeholder="MM/DD/YYYY"
        mask="_"
        allowEmptyFormatting
        customInput={Input}
        skipValidation
        {...props}
      />
      {error && (
        <Message color={red} css={labelCss}>
          {error}
        </Message>
      )}
    </InputWrapper>
  );
};

DateField.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  autofocus: PropTypes.bool,
  xLarge: PropTypes.bool,
  required: PropTypes.bool,
  isDirty: PropTypes.bool,
  labelCss: PropTypes.object,
};

export default DateField;
