import styled from '@emotion/styled/macro';
import {Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {gray} from '@renofi/utils/src/colors';

export default styled(Text)(
  mediaQuery({
    color: gray,
    fontSize: 18,
    lineHeight: '21px',
    fontWeight: '400',
  }),
  ({css}) => css,
);
