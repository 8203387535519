import React from 'react';

import {createRoot} from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import {HttpLink, ApolloClient, ApolloProvider, from} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {RetryLink} from '@apollo/client/link/retry';
import {onError} from '@apollo/client/link/error';

import {fetchWithRateLimitHandler, retryLinkConfig} from '@renofi/api';
import initPolyfills from '@renofi/utils/src/polyfills';
import {logGraphQLError} from '@renofi/analytics';

import './index.css';

import {initAnalytics} from './analytics';
import AppWrapper from './App/AppWrapper';
import {cache} from './api/cache';

initPolyfills();
initAnalytics();

const apiUrl = process.env.REACT_APP_GRAPHQL_PROXY_URL;
const httpLink = new HttpLink({
  uri: apiUrl,
  fetch: fetchWithRateLimitHandler,
});
const retryLink = new RetryLink(retryLinkConfig);
const authLink = setContext((_, {headers}) => {
  return {headers};
});
// Log any GraphQL errors or network error that occurred
const errorLink = onError(logGraphQLError);

const client = new ApolloClient({
  cache,
  name: process.env.REACT_APP_SERVICE_NAME,
  version: process.env.REACT_APP_COMMIT_REF,
  link: from([errorLink, authLink, retryLink, httpLink]),
});

const rootNode = document.getElementById('root');
const root = createRoot(rootNode);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Router>
        <AppWrapper />
      </Router>
    </ApolloProvider>
  </React.StrictMode>,
);
