import React, {useState} from 'react';

import {useHistory} from 'react-router-dom';
import {Flex, Box, Text} from 'rebass';

import {gray} from '@renofi/utils/src/colors';
import {Checkbox, Label, CheckboxLabel} from '@renofi/components';

import {Heading, Small, Footer} from '../components';
import {useRequestSoftCreditCheckByCoborrower} from '../api';
import {BASE_PATH} from '../const';

const Authorization = () => {
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [agreeConsent, setAgreeConsent] = useState(false);
  const hasAgreed = agreeTerms && agreeConsent;

  const history = useHistory();
  const {requestSoftCreditCheckByCoborrower, loading} =
    useRequestSoftCreditCheckByCoborrower();

  const onNext = async () => {
    try {
      await requestSoftCreditCheckByCoborrower();
    } finally {
      history.push(`${BASE_PATH}/finish`);
    }
  };

  const onBack = () => {
    history.push(`${BASE_PATH}/details`);
  };

  return (
    <>
      <Box pt={[0, 50]} pb={[0, 0]}>
        <Heading mt={[28, 13]} mb={[15, 21]}>
          Pre qualification authorization for soft credit inquiry
        </Heading>
        <Box width={['100%', '100%']} mx="auto">
          <Small>
            Our soft credit inquiries will <b>not</b> hurt or lower your credit
            score. Your credit report will not be seen by anyone other than our
            RenoFi team.
          </Small>
        </Box>
        <Flex
          width={['100%', '100%']}
          mx="auto"
          mt={24}
          justifyContent="center">
          <Flex
            flexDirection="column"
            textAlign="left"
            color={gray}
            fontSize={14}
            lineHeight="21px">
            <Text
              fontSize={18}
              color={'#000000'}
              fontWeight={600}
              width={1}
              py="6px">
              Terms of pre qualification
            </Text>
            <Text my={3}>
              I authorize RenoFi to make soft inquiries to one or more credit
              bureaus to obtain credit information about me to pre-qualify me
              for and to find renovation financing options available through
              RenoFi’s network of lending partners. I represent that I am
              authorized to submit this inquiry. RenoFi may share information it
              obtains through the soft credit inquiries within its corporate
              family, including Renovation Technologies Holdings Inc., and may
              tell our lending partners and service providers if you pre-qualify
              for loan options and I consent to sharing this information with
              these parties solely for the purpose of pre-qualification for
              renovation financing options. To opt-out of this sharing or for
              more information see our privacy notice located at{' '}
              <a
                href="https://www.renofi.com/notices/glba/"
                target="_blank"
                rel="noreferrer">
                https://www.renofi.com/notices/glba/
              </a>{' '}
              ("Privacy Notice").
            </Text>
            <Text my={3}>
              Soft credit inquiries are visible only to me on my credit report
              and will not impact my credit score. If I submit a credit
              application with a lender, the lender will make a hard credit pull
              that will impact my credit score. Any potential loan terms
              presented to me based on soft credit inquiries are preliminary and
              may change pending a full underwriting review if I submit a credit
              application.
            </Text>
            <Flex
              alignItems="center"
              css={{border: '2px solid #D8D8D8', padding: 12, borderRadius: 4}}
              mb={32}>
              <Label css={{cursor: 'pointer'}}>
                <Box minWidth={32}>
                  <Checkbox
                    checked={agreeTerms}
                    id="terms"
                    name="terms"
                    data-testid="agreeTerms"
                    onChange={() => setAgreeTerms(!agreeTerms)}
                  />
                </Box>
                <CheckboxLabel fontWeight={400} fontSize={16}>
                  I have read, understood and consent to the terms of pre
                  qualification outlined above.
                </CheckboxLabel>
              </Label>
            </Flex>
            <Flex
              alignItems="center"
              css={{border: '2px solid #D8D8D8', padding: 12, borderRadius: 4}}
              mb={32}>
              <Label css={{cursor: 'pointer'}}>
                <Box minWidth={32}>
                  <Checkbox
                    checked={agreeConsent}
                    id="consent"
                    name="consent"
                    data-testid="agreeConsent"
                    onChange={() => setAgreeConsent(!agreeConsent)}
                  />
                </Box>
                <CheckboxLabel fontWeight={400} fontSize={16}>
                  I have read and consent to the use of{' '}
                  <a
                    href="https://www.renofi.com/notices/electronic-records-and-signatures"
                    target="_blank"
                    rel="noreferrer">
                    Electronic Transactions/Signatures
                  </a>
                </CheckboxLabel>
              </Label>
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Footer
        showBack
        showNext
        disabled={!hasAgreed}
        onNext={onNext}
        onBack={onBack}
        loading={loading}
        tooltipText="Authorization is required"
      />
    </>
  );
};

export default Authorization;
