import React from 'react';

import PropTypes from 'prop-types';
import {Box, Flex, Link} from 'rebass';

import {Footer, Heading, Small} from '../components';

const Component = ({onNext, loading, disabled}) => {
  return (
    <>
      <Box pt={[0, 50]} pb={50} minHeight={300}>
        <Heading mt={[28, 13]} mb={[15, 21]}>
          Something went wrong
        </Heading>
        <Flex
          width={['100%', '100%']}
          mx="auto"
          alignItems="center"
          flexDirection="column">
          <Small>We were unable to complete verification process.</Small>
          <Link
            mt={24}
            fontSize={16}
            onClick={onNext}
            css={{cursor: 'pointer'}}>
            Click here to try again
          </Link>
        </Flex>
      </Box>
      <Footer
        disabled={disabled}
        loading={loading}
        showBack={false}
        showNext
        onNext={onNext}
        nextText="Try again"
      />
    </>
  );
};

Component.propTypes = {
  propName: PropTypes.string.isRequired,
};

export default Component;
