import {gql} from '@apollo/client';

export const UPDATE_COBORROWER_SOFT_CREDIT_CHECK = gql`
  mutation updateCoborrowerSoftCreditCheck(
    $token: String!
    $coborrowerDateOfBirth: Date!
  ) {
    result: updateCoborrowerSoftCreditCheck(
      token: $token
      coborrowerDateOfBirth: $coborrowerDateOfBirth
    ) {
      softCreditCheck {
        id
        coborrowerDateOfBirth
      }
    }
  }
`;
