import {useEffect, useState} from 'react';

import {useStartVerification} from '@renofi/api';
import {sendEvent} from '@renofi/analytics';

import {setIdentity} from '../api/cache';

export default ({email, projectId, identity, onInit, onComplete}) => {
  const [isLoading, setIsLoading] = useState(false);
  const {startVerification} = useStartVerification();

  useEffect(() => {
    sendEvent('Coborrower/Identity-Verification-Modal-Open');
  }, []);

  function initStripe() {
    loadStripeLibrary(openStripeModal);
  }

  function loadStripeLibrary(callback) {
    let script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/';
    script.onload = function () {
      callback();
    };
    document.head.appendChild(script); //or something of the likes
  }

  async function openStripeModal() {
    setIsLoading(true);
    const rsp = await startVerification({
      variables: {
        email,
        projectId,
        taskId: identity?.id,
        borrowerRole: 'coborrower',
      },
    });
    onInit && onInit();

    const clientSecret = rsp?.data?.startVerification?.clientSecret;
    const stripe = Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
    const result = await stripe?.verifyIdentity(clientSecret);

    if (!result.error) {
      sendEvent('Coborrower/Identity-Verification-Success', {
        taskId: identity.id,
        taskType: identity.taskType,
        identityVerificationResult: result,
      });

      // Update Apollo cache for other screens to see new identity data
      setIdentity({
        ...identity,
        identityVerifications: [
          ...identity?.identityVerifications,
          {borrowerRole: 'coborrower', taskId: identity.id},
        ],
      });
    } else {
      sendEvent('Coborrower/Identity-Verification-Error', {
        taskId: identity.id,
        taskType: identity.taskType,
        identityVerificationResult: result,
      });
    }

    setIsLoading(false);
    onComplete && onComplete(result);
  }

  return {initStripe, isStripeLoading: isLoading};
};
