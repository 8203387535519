import {InMemoryCache} from '@apollo/client';

import {coborrowerPolicy as coborrower} from './coborrower';
import {identityPolicy as identity} from './identity';

export const cache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {coborrower, identity},
    },
  },
});
