import React from 'react';

import {Tooltip} from 'react-tooltip';
import {Flex, Box} from 'rebass';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import {Button} from '@renofi/components';
import {useKeyDown, useScreenSize} from '@renofi/utils';

import PanelFooter from '../PanelFooter';

const Footer = ({
  onNext,
  onBack,
  disabled,
  loading,
  showBack = true,
  showNext = true,
  nextText = 'Continue',
  backText = 'Back',
  justifyContent,
  css,
  children,
  skipBackRedirect = false,
  tooltipText,
}) => {
  const {isMobile} = useScreenSize();
  const showTooltip = Boolean(disabled && tooltipText);
  const justify = justifyContent
    ? justifyContent
    : showBack
      ? 'space-between'
      : 'flex-end';

  const content = (
    <PanelFooter sticky css={css}>
      {children}
      <Flex width="100%" justifyContent={justify} px={[0, 20]}>
        {showTooltip && (
          <Tooltip id="tooltip" type="dark" effect="solid" opacity={1}>
            <span>{tooltipText}</span>
          </Tooltip>
        )}
        {showBack && (
          <Box width={[1, 'auto']} mr={[25, 0]}>
            <Button onClick={onBack} skipRedirect={skipBackRedirect} secondary>
              {backText}
            </Button>
          </Box>
        )}
        {showNext && (
          <Box data-tip data-for="tooltip" width={[1, 'auto']}>
            <Button
              onClick={onNext}
              internal
              disabled={disabled}
              loading={loading}>
              {nextText}
            </Button>
          </Box>
        )}
      </Flex>
    </PanelFooter>
  );

  useKeyDown('Enter', () => !disabled && onNext());

  return isMobile
    ? ReactDOM.createPortal(content, document.getElementById('footer-mobile'))
    : content;
};

Footer.propTypes = {
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  skipBackRedirect: PropTypes.bool,
  showBack: PropTypes.bool,
  showNext: PropTypes.any,
  nextText: PropTypes.string,
  children: PropTypes.node,
  css: PropTypes.object,
  justifyContent: PropTypes.string,
};

export default Footer;
