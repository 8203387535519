import {makeVar} from '@apollo/client';

export const identityVar = makeVar(null);

// Setter
export const setIdentity = (data) => {
  identityVar({...identityVar(), ...data});
};

// Apollo field policy
export const identityPolicy = {
  read() {
    return identityVar();
  },
};

export default {
  identityPolicy,
  setIdentity,
};
