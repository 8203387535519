import {gql} from '@apollo/client';

export const REQUEST_SOFTCREDIT_CHECK_BY_COBORROWER = gql`
  mutation requestSoftCreditCheckByCoborrower(
    $token: String!
    $coborrowerAuthorizedRenofiToVerifyCredit: Boolean!
  ) {
    result: requestSoftCreditCheckByCoborrower(
      token: $token
      coborrowerAuthorizedRenofiToVerifyCredit: $coborrowerAuthorizedRenofiToVerifyCredit
    ) {
      task {
        id
        status
      }
    }
  }
`;
