import {gql} from '@apollo/client';

export const SOFT_CREDIT_CHECK_SELF_REPORT_BY_COBORROWER = gql`
  mutation softCreditCheckSelfReportByCoborrower(
    $token: String!
    $coborrowerSelfReportedCreditScore: CreditScoreEnum!
  ) {
    result: softCreditCheckSelfReportByCoborrower(
      token: $token
      coborrowerSelfReportedCreditScore: $coborrowerSelfReportedCreditScore
    ) {
      task {
        id
        status
      }
    }
  }
`;
