import React, {memo} from 'react';

import PropTypes from 'prop-types';
import {FixedBottom} from 'react-fixed-bottom';
import {Box} from 'rebass';

import {useScreenSize} from '@renofi/utils';

import {Footer} from './styled';

const PanelFooter = ({children, sticky, ...props}) => {
  const {isMobile} = useScreenSize();
  const isSticky = isMobile && sticky;
  const Wrapper = isSticky ? FixedBottom : 'div';

  const style = isSticky
    ? {
        zIndex: 100,
        width: '100%',
        position: 'fixed',
        bottom: '0px !important',
        left: '0px',
      }
    : {};

  return (
    <Wrapper>
      <Box px={[15, 0]} css={style}>
        <Footer {...props} sticky={isSticky}>
          {children}
        </Footer>
      </Box>
    </Wrapper>
  );
};

PanelFooter.propTypes = {
  children: PropTypes.node,
  sticky: PropTypes.bool,
  css: PropTypes.object,
};

export default memo(PanelFooter);
