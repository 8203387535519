import React, {useState} from 'react';

import {useHistory} from 'react-router-dom';
import {Flex, Box} from 'rebass';

import {DateField, Label} from '@renofi/components';
import {splitDateString} from '@renofi/utils/src/date';

import {useUpdateCoborrowerSoftCreditCheck} from '../api';
import {Heading, Small, Footer} from '../components';
import {BASE_PATH} from '../const';
import {setCoborrower} from '../api/cache';
import {useIdentityVerification} from '../hooks';
import {isSoftCreditCheckNeeded, isVerificationNeeded} from '../utils';

import StripeCancelled from './StripeCancelled';

const DateOfBirth = ({identity, softCreditCheckTask, email, projectId}) => {
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [stripeCanceled, setStripeCanceled] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const {updateCoborrowerSoftCreditCheck} =
    useUpdateCoborrowerSoftCreditCheck();

  const history = useHistory();
  const {initStripe, isStripeLoading} = useIdentityVerification({
    identity,
    email,
    projectId,
    onInit: onStripeInit,
    onComplete: onStripeComplete,
  });

  const onNext = async () => {
    setLoading(true);

    const {day, month, year} = splitDateString(dateOfBirth);
    const isoDateString = `${year}-${month}-${day}`;

    await updateCoborrowerSoftCreditCheck(isoDateString);
    setCoborrower({coborrowerDateOfBirth: isoDateString});

    if (isVerificationNeeded(identity)) {
      return initStripe();
    }

    history.push(`${BASE_PATH}/details`);
  };

  const onBack = () => {
    history.push(`${BASE_PATH}/intro`);
  };

  const onChange = (value, error) => {
    setDateOfBirth(value);
    setDisabled(Boolean(error));
  };

  function onStripeInit() {
    setLoading(false);
  }

  function onStripeComplete(result) {
    setLoading(false);
    if (result.error) {
      setStripeCanceled(true);
      return;
    }

    const path = isSoftCreditCheckNeeded(softCreditCheckTask)
      ? '/details'
      : '/finish';
    history.push(`${BASE_PATH}${path}`);
  }
  function onReOpenStripe() {
    setLoading(true);
    if (!isStripeLoading) initStripe();
  }

  if (stripeCanceled) {
    return (
      <StripeCancelled
        disabled={disabled || loading || isStripeLoading}
        loading={loading}
        onNext={onReOpenStripe}
      />
    );
  }

  return (
    <>
      <Box pt={[0, 50]} pb={50}>
        <Heading mt={[28, 13]} mb={[15, 21]}>
          Before we start, help us confirm your identity
        </Heading>
        <Flex
          width={['100%', '100%']}
          mx="auto"
          alignItems="center"
          flexDirection="column">
          <Small>Please enter your date of birth.</Small>
          <Box width={[1, 1 / 4]} my={40}>
            <Label small htmlFor="date-of-birth" mt={16}>
              Date of Birth
            </Label>
            <DateField
              name="date-of-birth"
              id="date-of-birth"
              autofocus
              required
              isDirty
              value={dateOfBirth}
              onChange={onChange}
            />
          </Box>
        </Flex>
      </Box>
      <Footer
        showBack
        showNext
        disabled={disabled || loading || isStripeLoading}
        onNext={onNext}
        onBack={onBack}
        loading={loading}
        tooltipText="Date of birth is required"
      />
    </>
  );
};

export default DateOfBirth;
