import React from 'react';

import {useHistory} from 'react-router-dom';
import {useReactiveVar} from '@apollo/client';
import {Flex, Box, Text} from 'rebass';

import {gray} from '@renofi/utils/src/colors';
import {formatIsoDate} from '@renofi/utils/src/date';

import {Heading, Small, Footer} from '../components';
import {coborrowerVar} from '../api/cache';
import {BASE_PATH} from '../const';
import {getCoborrowerAddress} from '../utils';

const Details = () => {
  const history = useHistory();
  const coborrower = useReactiveVar(coborrowerVar);

  const onNext = async () => {
    history.push(`${BASE_PATH}/authorization`);
  };

  const onBack = () => {
    history.push(`${BASE_PATH}/date-of-birth`);
  };

  return (
    <>
      <Box mb={4} pt={[0, 50]} pb={[0, 0]}>
        <Heading mt={[28, 13]} mb={[15, 21]}>
          Pre qualification authorization for soft credit inquiry
        </Heading>
        <Box width={['100%', '100%']} mx="auto">
          <Small>
            Our soft credit inquiries will <b>not</b> hurt or lower your credit
            score. Your credit report will not be seen by anyone other than our
            RenoFi team.
          </Small>
        </Box>
        <Flex
          width={['100%', '100%']}
          mx="auto"
          my={24}
          justifyContent="center">
          <Flex
            flexDirection="column"
            textAlign="left"
            maxWidth={450}
            color={gray}
            fontSize={14}
            lineHeight="21px">
            <Text
              fontSize={18}
              color={'#000000'}
              fontWeight={600}
              width={1}
              py="6px">
              Review your personal details
            </Text>
            <Flex py="6px">
              <Text width={1 / 3}>Name</Text>
              <Text>
                {[
                  coborrower.coborrowerFirstName,
                  coborrower.coborrowerLastName,
                ].join(' ')}
              </Text>
            </Flex>
            <Flex py="6px">
              <Text width={1 / 3}>Date of birth</Text>
              <Text>{formatIsoDate(coborrower.coborrowerDateOfBirth)}</Text>
            </Flex>
            <Flex py="6px">
              <Text width={1 / 3}>Current address</Text>
              <Text width={2 / 3}>{getCoborrowerAddress(coborrower)}</Text>
            </Flex>
            <Text mt={16}>
              Need to update your information? Please contact{' '}
              {[coborrower?.firstName, coborrower?.lastName].join(' ')} to make
              any changes to your details.
            </Text>
          </Flex>
        </Flex>
      </Box>
      <Footer
        showBack
        showNext
        disabled={false}
        onNext={onNext}
        onBack={onBack}
        loading={false}
      />
    </>
  );
};

export default Details;
