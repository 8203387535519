import {gql} from '@apollo/client';

export const COBORROWER_TASKS = gql`
  query coborrowerTasks {
    result: coborrowerTasks {
      projectId
      identityVerificationTask {
        status
        id
        taskType
        identityVerifications {
          stripeVerificationSessionId
          borrowerRole
          taskId
          status
        }
      }
      softCreditCheckTask {
        status
        taskType
        softCreditCheck {
          id
          firstName
          lastName
          coborrowerFirstName
          coborrowerLastName
          coborrowerDateOfBirth
          coborrowerEmail
          coborrowerResidentialAddressCity
          coborrowerResidentialAddressState
          coborrowerResidentialAddressStreet
          coborrowerResidentialAddressZip
          coborrowerSelfReportedCreditScore
          status
          projectId
        }
      }
    }
  }
`;
