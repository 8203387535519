import {makeVar} from '@apollo/client';

export const coborrowerVar = makeVar({});

// Setter
export const setCoborrower = (data) => {
  coborrowerVar({...coborrowerVar(), ...data});
};

// Apollo field policy
export const coborrowerPolicy = {
  read() {
    return coborrowerVar();
  },
};

export default {
  coborrowerPolicy,
  setCoborrower,
};
