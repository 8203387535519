import {useMutation} from '@apollo/client';

import {useLocalStorage} from '@renofi/utils';

import {UPDATE_COBORROWER_SOFT_CREDIT_CHECK} from '../mutations';

export default function useUpdateCoborrowerSoftCreditCheck() {
  const [token] = useLocalStorage('renofi:token');
  const [fetch, {data, loading, error}] = useMutation(
    UPDATE_COBORROWER_SOFT_CREDIT_CHECK,
  );
  const response = data?.result?.softCreditCheck;

  const updateCoborrowerSoftCreditCheck = async (date) => {
    return await fetch({
      variables: {token, coborrowerDateOfBirth: date},
    });
  };

  return {updateCoborrowerSoftCreditCheck, response, loading, error};
}
