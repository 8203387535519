import styled from '@emotion/styled/macro';
import {Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const footerBg = () => ({
  position: 'absolute',
  zIndex: -1,
  content: ['none', '" "'],
  width: '100%',
  height: '80px',
  bottom: 0,
  backgroundImage: 'url(/img/footer.svg)',
});

export const StyledBackground = styled(Box)(
  mediaQuery({
    position: 'absolute',
    zIndex: 0,
    width: '100%',
    minHeight: '100%',
    overflow: 'hidden',
    background: [
      'white',
      'linear-gradient(0deg, #ECF7FB, #ECF7FB), linear-gradient(180deg, #E1F6FF 47.03%, #FFFFFF 100%)',
    ],
    ':after': footerBg(),
  }),
  ({css}) => mediaQuery(css),
);
