import {useQuery} from '@apollo/client';
import {useHistory} from 'react-router-dom';

import {getItem} from '@renofi/utils';

import {COBORROWER_TASKS} from '../queries';
import {setCoborrower, setIdentity} from '../cache';
import {BASE_PATH} from '../../const';

const UNAUTHORIZED_ERROR = 'UNAUTHORIZED_ERROR';
const UNAUTHENTICATED_ERROR = 'UNAUTHENTICATED_ERROR';

export default function useCoborrowerTasks() {
  const {refetch} = useQuery(COBORROWER_TASKS, {
    context: {
      headers: {
        authorization: getItem('coborrower-jwt'),
      },
    },
    errorPolicy: 'all',
    skip: true,
  });
  const history = useHistory();

  function onUnauthenticated() {
    history.push(`${BASE_PATH}/invalid-token`);
  }

  return async () => {
    try {
      const {data, errors} = await refetch();
      const coborrower = data?.result.softCreditCheckTask?.softCreditCheck;
      const identityTask = data?.result.identityVerificationTask;

      setCoborrower(coborrower);
      setIdentity(identityTask);

      const isInvalid = errors?.some(
        ({message}) =>
          message === UNAUTHORIZED_ERROR || message === UNAUTHENTICATED_ERROR,
      );

      if (isInvalid) {
        onUnauthenticated();
      }

      return data?.result;
    } catch (error) {
      if (
        error?.message === UNAUTHORIZED_ERROR ||
        error?.message === UNAUTHENTICATED_ERROR
      ) {
        onUnauthenticated();
      }
    }
  };
}
