import React from 'react';

import {Image} from 'rebass';

import {mediaQuery} from '@renofi/utils';

import {renofiLogoDarkIconSrc} from '../Icons';

import {HeaderWrapper} from './styled';

const Header = ({css = {}}) => {
  return (
    <HeaderWrapper>
      <Image
        css={mediaQuery({
          width: [130, 100],
          height: 'auto',
          marginLeft: [0, 15],
          ...css,
        })}
        src={renofiLogoDarkIconSrc}
        alt="RenoFi"
      />
    </HeaderWrapper>
  );
};

export default Header;
