import {useMutation} from '@apollo/client';

import {useLocalStorage} from '@renofi/utils';

import {REQUEST_SOFTCREDIT_CHECK_BY_COBORROWER} from '../mutations';

export default function useRequestSoftCreditCheckByCoborrower() {
  const [token] = useLocalStorage('renofi:token');
  const [fetch, {data, loading, error}] = useMutation(
    REQUEST_SOFTCREDIT_CHECK_BY_COBORROWER,
  );
  const response = data?.result?.task;

  const requestSoftCreditCheckByCoborrower = async () => {
    return await fetch({
      variables: {token, coborrowerAuthorizedRenofiToVerifyCredit: true},
    });
  };

  return {requestSoftCreditCheckByCoborrower, response, loading, error};
}
