import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import {AUTHENTICATE_BY_COBORROWER_TOKEN} from '../mutations/authenticateByCoborrowerToken';

export default function useAuthenticateByCoborrowerToken({
  onCompleted = noop,
} = {}) {
  const [authenticateByCoborrowerToken, {data, loading, error}] = useMutation(
    AUTHENTICATE_BY_COBORROWER_TOKEN,
    {
      onCompleted,
    },
  );
  const response = data?.authenticateByCoborrowerToken;
  return {authenticateByCoborrowerToken, response, loading, error};
}
