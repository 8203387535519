import React from 'react';

import {useHistory} from 'react-router-dom';
import {Box} from 'rebass';

import {Heading, Small, Footer} from '../components';
import {BASE_PATH} from '../const';

import {IntroIcon} from './img';

const Intro = () => {
  const history = useHistory();

  const onSubmit = () => {
    history.push(`${BASE_PATH}/date-of-birth`);
  };

  return (
    <>
      <Box pt={[0, 50]} pb={[0, 0]}>
        <IntroIcon />
        <Heading mt={[28, 35]} mb={[15, 17]}>
          We need your help preparing your file for review
        </Heading>
        <Box width={['100%', '100%']} mx="auto">
          <Small mb={[12, 24]}>
            Our renovation analysts will need to review your credit history as
            part of RenoFi’s pre qualification process.
          </Small>
          <Small mb={[12, 48]}>
            Take a few minutes to help us turn your renovation dreams into a
            reality today!
          </Small>
        </Box>
      </Box>
      <Footer showBack={false} onNext={onSubmit} nextText={`Let's go`} />
    </>
  );
};

export default Intro;
