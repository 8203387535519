import React from 'react';

import {Switch, Route, useHistory} from 'react-router-dom';
import {Box} from 'rebass';
import {ThemeProvider} from '@emotion/react';

import {breakpoint} from '@renofi/utils/src/mediaQuery';
import {NotificationsProvider, Header} from '@renofi/components';

import {Background} from '../components';
import Auth from '../Auth';

import App from './App';

const theme = {
  breakpoints: [`${breakpoint}px`],
};

function AppWrapper() {
  const history = useHistory();

  return (
    <Box css={{height: '100%', overflow: 'scroll'}}>
      <Switch>
        <Route path="/coborrower/token/:token/:path?" exact>
          <Auth
            failedPath="/coborrower/invalid-token"
            onSuccess={(token, path) => history.push(`/${path ? path : ''}`)}
          />
        </Route>
        <ThemeProvider theme={theme}>
          <NotificationsProvider>
            <Background css={{paddingBottom: [0, 'inherit']}}>
              <Header />
              <App />
            </Background>
          </NotificationsProvider>
        </ThemeProvider>
      </Switch>
    </Box>
  );
}

export default AppWrapper;
