import React from 'react';

import PropTypes from 'prop-types';

import {StyledBackground} from './styled';

const Background = ({children, css}) => {
  return <StyledBackground css={css}>{children}</StyledBackground>;
};

Background.propTypes = {
  children: PropTypes.node,
  css: PropTypes.object,
};

export default Background;
